<template>
    <div class="parent_view">
		<div>
			<h2>第一步：导出模版</h2>
			<p class="line_30">导出标准模板，填写黑名单表格	</p>
			<a-button  type="default" class="mr12 pointerCancel" @click='down_link' >下载模版</a-button>
		</div>

		<div class="mar_30" >
			<h2>第二步：导入文件</h2>
			<p class="line_30">导出标准模板，填写黑名单表格	</p>
			<a-upload  name="file" :customRequest="handle_custom_request"
			    :before-upload="handle_before_upload" >
			    <p class="ant-upload-drag-icon">
			        <!-- <inbox-outlined></inbox-outlined> -->
			    </p>
				<a-button type="primary" class="mr12 pointerCancel">导入文件</a-button>
			</a-upload>
		</div>
        <a-modal width="50%" v-model:visible="visibleWIFI" title="" @ok="visibleWIFI=false">
            <div style="text-align: center;w
            100%">
                <a-progress type="circle" :stroke-color="{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                      }" :percent="percent" />
                <p style="margin-top: 20px;">上传中...</p>
            </div>

        </a-modal>
    </div>
</template>

<script>
    import {
       importBlacklistUser,exportBlacklistUserTemplate
    } from '@/api/enterprise.js'

    import {
        message
    } from 'ant-design-vue';
    import moment, {
        Moment
    } from 'moment';
    export default {
        props: ['type'],
        components: {},
        data() {
            return {
                percent: 90,
                visibleWIFI: false,
            }
        },
        created() {
           
        },

        methods: {
            down_link(){
                 this.$method.handleExport({}, '/manage/company.special_user/exportBlacklistUserTemplate')
            },
            type_impoirt(filename) {
                    importBlacklistUser({
                        data: {
                            file_name: filename
                        },
                        info: true
                    }).then(res => {
						this.$emit('success')
                        this.percent = 100;
						setTimeout(()=>{ this.visibleWIFI = false},2000)
                       
                    }).catch(res => {
                    this.visibleWIFI = false
                })
               
            },
           
			handle_before_upload(){},
            handle_custom_request(file) {
				let that = this
                this.visibleWIFI = true
				this.$req({
					mode: 'file',
					url: '/manage/api.plugs/upload',
					data: {
						file: file.file,
					}
				}).then(res => {
					that.type_impoirt(res.data.upload_data.filename)
					
				}).catch(e => {
					this.visibleWIFI = false
				})
				
				
            },
        },
        computed: {

        },

    }
</script>

<style lang="less" scoped="scoped">
	@import url("../../assets/less/app.less");
	 @import 'info.less';
    .mac_input {
        width: 60px;

        display: inline-block;
    }

    .dot_line {
        width: 10px;
        display: inline-block;
        text-align: center;
    }

    .parent_view {
        background: #fff;
        padding: 12px;
        margin: 12px;
    }

    .upload {
        padding: 30px;
        height: 300px;
        width: 80%;
        margin: 0 auto;
    }

    .tips_info {
        padding: 30px;
        width: 80%;
        margin: 0 auto;
    }

    /deep/.ant-upload-list {
        display: none;
    }

  .tips_top_box {
      font-size: 14px;
      .title{
          font-size: 16px;
          font-weight: 600;
          color: #333;
          margin-bottom: 20px;
          margin-top: -20px;
      }
      p {
          line-height: 26px;
          color: #80848f;
      }
  
      margin-bottom: 30px;
  }
  .tips_ber p:before{
          position: relative;
          top: -3px;
          display: inline-block;
          width: 5px;
          height: 5px;
          margin-right: 5px;
          background: #80848f;
          border-radius: 50%;
          content: "";
  }
  .down_link{
      color: @cq_color;
  }
  .line_30{
  	line-height: 60px;
  }
  .mar_30{
	  margin-top: 30px;
  }
</style>
