<template>
	<div class="enterprise">
		<a-tabs v-model:activeKey="activeKey">
			<a-tab-pane key="1" tab="高管隐私保护 ">
				<TableList :columns="columns" :get_table_list="this.get_table_list1" :slot_table_list="['op','icon']"
					ref="list">
					<template slot="left_btn">
						<div style="display: flex;width: 100%;">
							<div id="" style="flex: 1;" class="search_form">
								<span class="cq_text_require">*</span><span id="">
									以下员工被设置为高管隐私保护，无“高管隐私”权限的子账号将不能查看Ta的详细信息。</span>
							</div>
							<a-button v-auth="'change'" type="primary" class="mr12 " @click="chooseUser=true">添加
							</a-button>
							<a-button v-auth="'export'" class="mr12 pointerCancel"
								@click="down_link('/manage/company.special_user/exportConcealUser')">导出
							</a-button>
						</div>

					</template>

					<template #op="{record}">
						<div class="op">
							<a-popconfirm :title="'是否确认取消'+record.username+'的隐私保护?'" ok-text="是" cancel-text="否"
								@confirm="del_open(record.id)">
								<span class="check del"> 取消 </span>
							</a-popconfirm>

						</div>
					</template>
				</TableList>
			</a-tab-pane>
			<a-tab-pane key="2" tab="黑名单 ">
				<TableList :rowKey="'id'" :columns="columns2" :rowSelection='rowSelection'
					:get_table_list="this.get_table_list2" :slot_table_list="['op','icon']" ref="list2">
					<template slot="left_btn">
						<div style="display: flex;width: 100%;">
							<div id="" style="flex: 1;" class="search_form">
								<span class="cq_text_require">*</span><span id="">
									被列入黑名单的人员，在招聘、入职环节，系统会自动提醒，防止误录。</span>
							</div>
							<div>
								<a-dropdown>
									<template #overlay>
										<a-menu v-auth="'change'">
											<a-menu-item key="1" class="mr12 pointerCancel"
												@click="addVisibleNone=true,editType='add'">单个添加
											</a-menu-item>
											<a-menu-item key="2" class="mr12 pointerCancel"
												@click='VisibleUpload=true'>批量添加</a-menu-item>
										</a-menu>
									</template>
									<a-button v-auth="'change'" type="primary " class="mr12 pointerCancel"
										:disabled="checkable">添加
										<!-- <DownOutlined /> -->
									</a-button>
								</a-dropdown>
								<a-dropdown>
									<template #overlay>
										<a-menu v-auth="'export'">
											<a-menu-item key="1" class="mr12 pointerCancel "
												@click="down_link('/manage/company.special_user/exportBlacklistUser')">
												导出黑名单</a-menu-item>

										</a-menu>
									</template>
									<a-button class="mr12 pointerCancel" :disabled="checkable">更多功能
										<!-- <DownOutlined /> -->
									</a-button>
								</a-dropdown>
							</div>
						</div>

					</template>

					<template #op="{record}">
						<div class="op">
							<span v-auth="'change'" style='margn-right:10px;display: inline-block;'
								@click="get_info(record)" class="check">编辑</span>

							<a-popconfirm title="是否确认移除?" ok-text="是" cancel-text="否" @confirm="remove_open(record.id)">
								<span class="check del"> 移除 </span>
							</a-popconfirm>
						</div>
					</template>
				</TableList>
				<!-- 				
				<List @onSelectChange="onSelectChange" :where='where2' :checkable='checkable' :search_form="search_form" :api="api2"
					:columns="columns2" ref="list2">
					<template #count>
						<div class="breadcrumbTop">
							<div class="count_info" style="">
								<div id="" style="flex: 1;" class="search_form">
									<span class="cq_text_require">*</span><span id="">
										被列入黑名单的人员，在招聘、入职环节，系统会自动提醒，防止误录。</span>
								</div>
				
							</div>
							<div class="count_info" style="">
								<div id="" class="search_form">
								<a-input v-model:value="where2.keyword" placeholder="请输入关键字搜索"
									@pressEnter="filterSearch2">
									<template #prefix>
										<SearchOutlined style='color: #999;' type="user" />
									</template>
								</a-input>
								</div>
									<a-popover v-model:visible="filterVisible" placement="bottom" trigger="click">
									    <template #content>
									        <Form :form="search_form_com" @handle_submit="filterSearch2"></Form>
									    </template>
									    <span class="FilterOutlined" @click="filterVisible=true"></span>
									</a-popover>
								
								<div style="flex: 1;text-align: right;">
									<a-dropdown>
										<template #overlay>
											<a-menu>
												<a-menu-item key="1" @click="addVisibleNone=true,editType='add'">单个添加
												</a-menu-item>
												<a-menu-item key="2" @click='VisibleUpload=true'>批量添加</a-menu-item>
											</a-menu>
										</template>
										<a-button type="primary" class="mr12 " :disabled="checkable">添加
											<DownOutlined />
										</a-button>
									</a-dropdown>
									<a-dropdown>
										<template #overlay>
											<a-menu>
												<a-menu-item key="1"
													@click="down_link('/manage/company.special_user/exportBlacklistUser')">
													导出黑名单</a-menu-item>
												<a-menu-item key="2" @click='checkable=true'>批量移除</a-menu-item>
											</a-menu>
										</template>
										<a-button class="mr12 " :disabled="checkable">更多功能
											<DownOutlined />
										</a-button>
									</a-dropdown>
								</div>
							
							</div>
						</div>
					</template>
					<template #op="{record}">
						<div class="op">
							<span @click="get_info(record.id)" class="check">编辑</span>
							<a-divider type="vertical" />
							<a-popconfirm title="是否确认移除?" ok-text="是" cancel-text="否" @confirm="remove_open(record.id)">
								<span class="check del"> 移除 </span>
							</a-popconfirm>
						</div>
					</template>
				</List> -->
				<div>
					<a-drawer placement="bottom" height="70" :mask="false" :visible="batchDeleteVisible"
						:closable="false">
						<a-button v-auth="'delete'" style="margin-left: 45%;" class='pointerCancel'
							@click='batchDeleteVisible=false;'>取消</a-button>
						<a-button v-auth="'delete'" type="primary pointerCancel" style="margin-left: 16px;"
							@click='all_del'>批量移除</a-button>
					</a-drawer>
				</div>
			</a-tab-pane>
		</a-tabs>
		<a-modal v-model:visible="addVisibleNone" title="新增" @ok="addNoneTextOk" @cancel="addCancel">
			<a-form :model="formState" :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
				<a-form-item required label="姓名" class='item_p'>
					<a-input v-model:value="formState.username" placeholder="请输入姓名" />
				</a-form-item>
				<a-form-item required req label="手机号码" class='item_p'>
					<a-input v-model:value="formState.phone" type='number' placeholder="请输入手机号码" />
				</a-form-item>
				<a-form-item  label="身份证号" class='item_p'>
					<a-input required v-model:value="formState.id_card" placeholder="请输入身份证号" />
				</a-form-item>
				<a-form-item required label="添加原因" class='item_p' style='margin-top:10px'>
					<a-textarea v-model:value="formState.reason" placeholder="请输入添加原因" />
				</a-form-item>
			</a-form>
		</a-modal>


		<a-modal v-model:visible="chooseUser" title="新增" @ok="department_ok">
			<TransferTree @change="TransferTreeChange" :treeData.sync="treeData" :value.sync="treeDataValue" />
		</a-modal>

		<a-modal v-model:visible="VisibleUpload" title="新增" @ok="VisibleUpload=false">
			<upload @success='successFile'></upload>
		</a-modal>
		<!-- <DepartmentSelect ref="DepartmentSelect" @ok="department_ok"></DepartmentSelect> -->
	</div>
</template>

<script>
	import {
		exportBlacklistUser,
		getConcealUserList,
		getBlacklistUserList,
		addConcealUser,
		cancleConcealUser,
		exportConcealUser,
		addBlacklistUser,
		editBlacklistUser,
		cancleBlacklistUser,
		batchCancleBlacklistUser
	} from '@/api/enterprise.js'
	import TransferTree from "@/components/TransferTree";
	import TableList from "@/components/TableList";
	// import DepartmentSelect from '@/components/pop/DepartmentSelect'
	import upload from './upload.vue'
	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal,
	} from 'ant-design-vue';

	export default {
		components: {
			TableList,
			TransferTree,
			// DepartmentSelect,
			upload
		},
		data() {
			return {
				activeKey: '1',
				page: 1,
				pagination: {
					...this.$config.pagination
				},
				chooseUser: false,
				treeData: [],
				treeDataValue: [],
				filterVisible: false,
				where: {},
				where2: {},
				checkable: false,
				labelColEdit: {
					span: 5
				},
				wrapperColEdit: {
					span: 18
				},
				search_form2: {
					list: [{
							type: 'date',
							name: 'start_time',
							label: '开始时间',

						},
						{
							type: 'date',
							name: 'end_time',
							label: '结束时间',

						},
						{
							type: 'text',
							name: 'id_card',
							label: '身份证',

						},

					]
				},
				choose_list: [],
				VisibleUpload: false,
				batchDeleteVisible: false,
				editType: 'add',
				formState: {},
				search_form: false,
				rule_user: [],
				get_table_list1: getConcealUserList,
				get_table_list2: getBlacklistUserList,
				addVisibleNone: false,
				columns: [{
						title: '姓名',
						dataIndex: 'username',
						key: 'username',
					},
					{
						title: '工号',
						dataIndex: 'drp_coding',
						key: 'drp_coding',
					},
					{
						title: '所属公司',
						align: 'center',
						dataIndex: 'company_name',
						key: 'company_name',
					},
					{
						title: '部门',
						dataIndex: 'department_name',
						align: 'center',
						key: 'department_name',

					},


					{
						title: '操作',
						dataIndex: 'op',
						key: 'op',

						scopedSlots: {
							customRender: 'op'
						},
					},
				],

				columns2: [{
						title: '姓名',
						dataIndex: 'username',
						key: 'username',
						width: '160px',
					},
					{
						title: '手机号码',
						dataIndex: 'phone',
						key: 'phone',
						width: '120px',
					},
					{
						title: '身份证号码',
						align: 'center',
						dataIndex: 'id_card',
						key: 'id_card',
						width: '120px',
					},
					{
						title: '添加人',
						dataIndex: 'creator',
						align: 'center',
						key: 'creator',

					},
					{
						title: '添加原因',
						dataIndex: 'reason',
						align: 'center',
						key: 'reason',
					},
					{
						title: '添加时间',
						dataIndex: 'create_time',
						align: 'center',
						key: 'create_time',
					},

					{
						title: '操作',
						dataIndex: 'op',
						key: 'op',
						width: '180px',
						scopedSlots: {
							customRender: 'op'
						},
					},
				],
				selectedRowKeys: [],
				customer_ids: ''
			}

		},
		created() {
			this.$method.get_user_department().then(res => {
				this.treeData = res;
			});

		},
		computed: {
			search_form_com() {
				return {
					is_btns: true,
					layout: 'inline',
					submit_text: '搜索',
					...this.search_form2
				}
			},
			rowSelection() {
				const {
					selectedRowKeys
				} = this;
				return {
					selectedRowKeys,
					onChange: (selectedRowKeys, selectedRows) => {
						this.selectedRowKeys = selectedRowKeys;
						this.customer_ids = selectedRowKeys.join(',')
						if (selectedRowKeys.length > 0) {
							this.batchDeleteVisible = true
						} else {
							this.batchDeleteVisible = false
						}
					},
					hideDefaultSelections: true,
				};
			}
		},

		methods: {

			successFile() {
				this.VisibleUpload = false
			},
			get_info(item) {
				this.formState = JSON.parse(JSON.stringify(item))
				this.addVisibleNone = true
			},
			addCancel() {
				this.formState = {}
			},
			all_del() {
				batchCancleBlacklistUser({
					data: {
						ids: this.customer_ids
					}
				}).then(res => {
					message.success('操作成功');
					this.selectedRowKeys = []
					this.batchDeleteVisible = false
					this.$refs.list2.get_list()
				})
			},
			onSelectChange(e) {


			},
			down_link(url) {
				this.$method.handleExport({}, url)
			},
			filterSearch() {
				this.$refs.list.get_list()
			},
			filterSearch2(e) {
				if (e) {
					this.where2.end_time = e.end_time
					this.where2.start_time = e.start_time
					this.where2.id_card = e.id_card
				}

				this.$refs.list2.get_list()
			},
			addNoneTextOk() {


				var reg = /^1[3456789]\d{9}$/;
				
				if (!reg.test(this.formState.phone)) {
					message.error('请输入正确的手机号码！');
					return false
				}

				addBlacklistUser({
					data: this.formState
				}).then(res => {
					message.success('操作成功');
					this.formState = {}
					this.addVisibleNone = false
					this.$refs.list2.get_list()
				})
			},
			remove_open(id) {
				cancleBlacklistUser({
					data: {
						id: id
					}
				}).then(res => {
					message.success('操作成功');
					this.$refs.list2.get_list()
				})
			},
			del_open(id) {
				cancleConcealUser({
					data: {
						id: id
					}
				}).then(res => {
					message.success('操作成功');
					this.$refs.list.get_list()
				})
			},
			TransferTreeChange(value) {
				this.rule_user = value
			},
			department_ok() {

				let deptUsers = [];
				this.rule_user.map(item => {
					let itemtemp = item.split('_')
					deptUsers.push({
						id: itemtemp[1],
						type: itemtemp[0],
					})
				})
				addConcealUser({
					data: {
						user_range: deptUsers
					}
				}).then(res => {
					message.success('操作成功');
					this.$refs.list.get_list()
					this.chooseUser = false

				})
			},
			department_user_select_show() {
				// this.$refs.DepartmentSelect.selected_option = [];
				// this.$refs.DepartmentSelect.check_is_all_select();
				// this.$refs.DepartmentSelect.visible = true;
			},


			handle_submit() {

			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.FieldSettings {
		padding: 16px;
		margin-right:16px;

	}

	.table_list_body {
		margin: 0;
		padding: 0;
	}

	.check {
		color: @cq_color;
		cursor: pointer;
	}

	.del {
		color: @cq_error;
	}

	.count_info {
		width: 100%;
		display: flex;
		padding-top: 12px;
	}

	.FilterOutlined {
		height: 30px;
		width: 30px;
	}

	.mr12 {
		margin-right: 12px;
	}

	.item_p {
		margin-bottom: 7px;
	}
</style>